<template>
    <el-dialog v-model="dialogVisible" title="选择文件" :close-on-click-modal="false" width="600px">
        <div class="confirmbox">
            <div class="c-999 h-20 lh-20 mb-10">（已选{{ state.selectionCount }}项）</div>
            <el-table :data="state.Notice" class="mb-20" :header-cell-style="{
                background: 'var(--active-alpha)',
                color: config.table.color,
                fontWeight: config.table.fontWeight,
                fontSize: config.table.fontSize,
            }" row-key="id"  @selection-change="selectionChange">
                <el-table-column :align="'center'" type="selection" width="55" />
                <el-table-column prop="name1" label="合同编号" />
                <el-table-column prop="name2" label="合同标题" />
            </el-table>
        </div>
        <template #footer>
            <span class="dialog-footer dp-f fd-rr">
                <oabutton class="searcML" width='120' height='40' title="确定" CSStype=2 @buttonclick="submit()"
                    v-model:loading="dialogloading"></oabutton>
                <oabutton class="searcML" width='120' height='40' title="取消" CSStype=3
                    @buttonclick="dialogVisible = false">
                </oabutton>
            </span>
        </template>
    </el-dialog>
</template>
<script setup>
import { reactive, ref, unref, nextTick, defineEmits } from 'vue'
import qs from "qs";
const dialogloading = ref(false);// 弹框按钮loading 显示
const dialogVisible = ref(false)//弹框显示
const emit = defineEmits(['Emit'])
const id = ref(0);//操作的id
const state = reactive({
    selectionCount: 0,//选中数量
    Notice: []
})
const getData = ((data) => {
    state.Notice = data
    dialogVisible.value = true
})
// 数据提交
const submit = (() => {

})
// 表格选中事件
const selectionChange = (val) => {
    // console.log('选中改变',val)
    state.selectionCount = val.length
}
defineExpose({
    getData
});
</script>
<style lang="scss" scoped >
.confirmbox {
    ::v-deep .el-table .el-table__cell {
        height: 50px;
    }
}
</style>